








































































































import { Component, Watch } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import VideoTemplatePage from "@/components/SupportAndDownload/Download/VideoTemplatePage.vue"
import ImageTemplatePage from "@/components/SupportAndDownload/Download/ImageTemplatePage.vue"
import { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import DownloadHeader from "@/types/SupportAndDownload/Download/DownloadHeader"
import DownloadApi from "@/api/SupportAndDownload/DownloadApi"
// eslint-disable-next-line
import { Route } from "vue-router"

@Component({
  name: "DownloadPage",
  components: { VideoTemplatePage, ImageTemplatePage, ViewWrapper }
})
export default class DownloadPage extends mixins(TabMixin, InputMixin, BreakpointMixin) {
  private menu = 0

  private menus: Partial<DownloadHeader>[] = []

  private isLoading: Boolean = true
  // private previouseLang: string;

  private get tab() {
    return _.findIndex(this.menus, ["downloadHeaderId", +this.$route.query.id])
    // return this.$route.query.id
  }

  get currentMenuIndex(): number {
    return _.findIndex(this.menus, ["downloadHeaderId", +this.$route.query.id])
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(from: Route, to: Route) {
    if (this.$route.query.view === "support") return
    if (
      from.query.view === this.$route.query.view &&
      from.query.lang === this.$route.query.lang &&
      from.query.id !== this.$route.query.id
    ) {
      return
    }
    if (from && to && from.query && to.query && from.query.lang === to.query.lang) this.fetchData()
    else if (this.$route.query.id && from.query.lang === to.query.lang) {
    } else this.fetchData(true)
  }

  private async fetchData(reset = false) {
    DownloadApi.listHeaders().then(({ data }) => {
      const temp: Partial<DownloadHeader>[] = []
      _.forEach(data, (header, idx) => {
        const headerObj = deserialize<DownloadHeader>(header, DownloadHeader)
        headerObj.id = parseInt(idx, 10) + this.menus.length
        if (headerObj.isFile) {
          headerObj.component = ImageTemplatePage
        } else {
          headerObj.component = VideoTemplatePage
        }
        temp.push(headerObj)
      })
      this.menus = _.cloneDeep(temp)
      const { id } = this.$route.query
      const tabId = Array.isArray(id) ? id[0] : id;
      if (reset) {
        // const setDefaultId
        this.$router.push({
          name: "SupportAndDownload",
          query: { view: this.$route.query.view, id: this.setDefaultTab(tabId || "3"), lang: this.$i18n.locale, top: `${true}` }
        })
        this.menu = _.first(this.menus)?.downloadHeaderId!
      } else if (id) {
        this.$router.push({
          name: "SupportAndDownload",
          query: {
            view: this.$route.query.view,
            id: `${parseInt(`${id}`, 10)}`,
            lang: this.$i18n.locale,
            top: `${true}`
          }
        })
        this.menu = parseInt(`${id}`, 10)
      } else {
        this.menu = _.first(this.menus)?.downloadHeaderId || 1
      }
    })
  }

  async mounted() {
    const { id } = this.$route.query
    if (!id || id === "-2") {
      await this.fetchData(true)
    } else {
      await this.fetchData()
    }
    this.isLoading = false
  }

  private changeTab(id: number) {
    if (this.menu === id) {
      return
    }
    this.$router.push({
      name: "SupportAndDownload",
      query: { view: "download", id: `${id}`, lang: this.$i18n.locale, top: `${true}` }
    })
  }

  private setDefaultTab(tabId: string) {
    let tabNumber
    switch (tabId) {
      case "1":
        tabNumber = "7"
        break
      case "2":
        tabNumber = "8"
        break
      case "3":
      case "4":
        tabNumber = "18"
        break
      case "5":
        tabNumber = "9"
        break
      case "12":
        tabNumber = "15"
        break
      case "13":
        tabNumber = "10"
        break
      case "14":
        tabNumber = "17"
        break
      case "7":
        tabNumber = "1"
        break
      case "18":
        tabNumber = "3"
        break
      case "10":
        tabNumber = "13"
        break
      case "15":
        tabNumber = "12"
        break
      case "8":
        tabNumber = "2"
        break
      case "17":
        tabNumber = "14"
        break
      case "9":
        tabNumber = "5"
        break
      default:
        tabNumber = "3"
    }
    return tabNumber
  }

  private generateRoute(id: number) {
    return {
      name: "SupportAndDownload",
      query: { view: "download", id: `${id}`, lang: this.$i18n.locale, top: true }
    }
  }

  getTabClass(id: number) {
    let cls = ""
    if (id === this.menus[0].downloadHeaderId) {
      cls = cls.concat("pl-6 ml-16 ")
    }
    if (`${this.tab}` === `${_.findIndex(this.menus, ["downloadHeaderId", id])}`) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold black--text")
    } else {
      cls = cls.concat("font-weight-light black--text")
    }
    return cls
  }
}
