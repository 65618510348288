








































































































import { Component, Watch } from "vue-property-decorator"
import ViewWrapper from "@/views/ViewWrapper.vue"
import FAQPage from "@/components/SupportAndDownload/Support/FAQPage.vue"
import SupportHeader from "@/types/SupportAndDownload/Support/SupportHeader"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import VideoTemplatePage from "@/components/SupportAndDownload/Support/VideoTemplatePage.vue"
import ImageTemplatePage from "@/components/SupportAndDownload/Support/ImageTemplatePage.vue"
import { mixins } from "vue-class-component"
import TabMixin from "@/types/TabMixin"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import DiamondSiteRefPage from "@/components/SupportAndDownload/Support/DiamondSiteRefPage.vue"
// eslint-disable-next-line
import { Route } from "vue-router"
import BlogPage from "@/components/SupportAndDownload/Support/BlogPage.vue"

@Component({
  name: "SupportPage",
  components: { VideoTemplatePage, ImageTemplatePage, ViewWrapper }
})
export default class SupportPage extends mixins(TabMixin, InputMixin, BreakpointMixin) {
  private menu = -2

  private menus: Partial<SupportHeader>[] = [
    {
      id: 0,
      supportHeaderId: -2,
      title: "faq",
      hover: false,
      isFile: false,
      filter: true,
      static: true,
      component: FAQPage
    },
    {
      id: 1,
      supportHeaderId: -1,
      title: "siteRef",
      hover: false,
      isFile: false,
      filter: true,
      static: true,
      component: DiamondSiteRefPage
    },
    {
      id: 2,
      supportHeaderId: 0,
      title: "blog",
      hover: false,
      isFile: false,
      filter: true,
      static: true,
      component: BlogPage
    }
    // {
    //   supportHeaderId: 0,
    //   title: 'บทความ',
    //   hover: false,
    //   isFile: false,
    //   filter: true,
    //   static: true,
    //   component: FAQPage,
    // },
  ]

  private isLoading: Boolean = true

  private get tab() {
    // return _.findIndex(this.menus, ["supportHeaderId", this.menu])
    return this.$route.query.id || -2
  }

  private changeTab(id: number) {
    if (this.menu === id) {
      return
    }
    const lang = this.$i18n.locale
    this.$router.push({
      name: "SupportAndDownload",
      query: { view: "support", id: `${id}`, lang, top: `${true}` }
    })
  }

  private generateRoute(id: number) {
    return {
      name: "SupportAndDownload",
      query: { view: "support", id: `${id}`, lang: this.$i18n.locale, top: true }
    }
  }

  get currentMenuIndex(): number {
    // return _.findIndex(this.menus, ["supportHeaderId", this.menu])
    return +this.$route.query.id || -2
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(from: Route, to: Route) {
    // if (this.$route.query.view === 'download') return
    if (from.query.view === this.$route.query.view && from.query.lang === this.$route.query.lang && from.query.id !== this.$route.query.id) return
    
    // Router from vue-router
    if (from && to && from.query && to.query && from.query.lang === to.query.lang) this.fetchData()
    else this.fetchData(true)
    window.dispatchEvent(new Event("resize"))
  }

  private fetchData(reset = false) {
    SupportApi.listHeaders().then(({ data }) => {
      const staticLength = this.menus.length
      const temp: SupportHeader[] = []
      _.forEach(data, (header, idx) => {
        const headerObj = deserialize<SupportHeader>(header, SupportHeader)
        headerObj.id = parseInt(idx, 10) + staticLength
        if (headerObj.isFile) {
          headerObj.component = ImageTemplatePage
        } else {
          headerObj.component = VideoTemplatePage
        }
        temp.push(headerObj)
      })

      let _menus: Partial<SupportHeader>[] = []
      if (this.$store.state.menus.blogs !== "ACTIVE") {
        _menus = this.menus.slice(0, 2)
      } else {
        _menus = this.menus.slice(0, 3)
      }

      this.menus = _.concat(_menus, temp)

      const { id } = this.$route.query || -2
      if (reset) {
        this.menu = -2
      } else if (`${id}`) {
        this.menu = parseInt(`${id}`, 10)
      }
    })
  }

  async mounted() {
    const { id } = this.$route.query

    if (id !== undefined) {
      await this.fetchData()
    } else {
      await this.fetchData(true)
    }
    this.isLoading = false
  }

  getTabClass(id: number) {
    let cls = ""

    if (id === this.menus[0].supportHeaderId) {
      cls = cls.concat("pl-6 ml-16 ")
    }
    if (`${this.tab}` === `${id}`) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold black--text")
    } else {
      cls = cls.concat("font-weight-light black--text")
    }
    return cls
  }
}
