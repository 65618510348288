

























import { Component, Prop, PropSync } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "ImageDialog"
})
export default class ImageDialog extends mixins(BreakpointMixin) {
  @PropSync("model", { default: false }) dialog!: boolean

  @Prop() imageUrl!: string

  @Prop({ default: true }) persistent!: boolean

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 635
    }
    if (bp.lgOnly) {
      return 424
    }
    return 155
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 1271
    }
    if (bp.lgOnly) {
      return 847
    }
    return 310
  }
}
