




















































































import { Component, Prop, Watch } from "vue-property-decorator"
// import ProductGroup from "@/types/Product/ProductGroup"
import YoutubeDialog from "@/components/Global/YoutubeDialog.vue"
// @ts-ignore
import { getIdFromUrl } from "vue-youtube"
// import ProductApi from "@/api/Product/ProductApi"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import Download from "@/types/SupportAndDownload/Download/Download"
import DownloadApi from "@/api/SupportAndDownload/DownloadApi"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import SupportApi from "@/api/SupportAndDownload/SupportApi"

@Component({
  name: "VideoTemplatePage",
  components: { YoutubeDialog }
})
export default class VideoTemplatePage extends mixins(InputMixin, BreakpointMixin) {
  @Prop()
  hasFilter!: boolean

  @Prop()
  downloadHeaderId!: number

  productGroups: SiteRefProductGroup[] = []

  data: Download[] = [] // TODO

  productGroup = 1

  private updating = false

  private youtubeDialog = false

  private watchingId = ""

  mounted() {
    if (this.hasFilter) {
      SupportApi.listSiteRefProductGroupsFilter()
        .then(({ data }) => {
          this.productGroups = _.map(data, (d) => deserialize<SiteRefProductGroup>(d, SiteRefProductGroup))
          this.productGroup = this.productGroups[0].productGroupId
        })
        .then(this.fetchData)
    } else {
      this.fetchData()
    }
  }

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.mdAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  private fetchData() {
    this.updating = true
    const productGroup: number | undefined = this.hasFilter ? this.productGroup : undefined
    DownloadApi.listDownloads(this.downloadHeaderId, productGroup)
      .then(({ data }) => {
        this.data = _.map(data, (d) => deserialize<Download>(d, Download))
      })
      .finally(() => {
        this.updating = false
      })
  }

  @Watch("productGroup")
  onProductGroupChange() {
    this.fetchData()
  }

  private watchYoutube(item: Download) {
    this.watchingId = getIdFromUrl(item.link)
    this.youtubeDialog = true
  }
}
