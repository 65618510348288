











import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import SiteRefList from "@/components/SupportAndDownload/Support/SiteRefList.vue"
import BlogList from "@/components/SupportAndDownload/Support/BlogList.vue"
import BlogDetail from "@/components/SupportAndDownload/Support/BlogDetail.vue"
import InputMixin from "../../../types/InputMixin"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "BlogPage",
  components: { SiteRefList }
})
export default class BlogPage extends mixins(BreakpointMixin, InputMixin) {
  private appState = 0

  private blogId = -1

  private state = {
    0: BlogList,
    1: BlogDetail
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.refreshState()
  }

  mounted() {
    this.refreshState()
  }

  refreshState() {
    if (!this.$route) return
    const queryState = this.$route.query.state
    const queryBlogId = this.$route.query.blog
    if (queryState && !queryBlogId) {
      this.appState = parseInt(queryState as string, 10)
    } else if (queryBlogId) {
      this.blogId = parseInt(queryBlogId as string, 10)
      this.appState = 1
    }
  }

  private back(target: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        state: `${0}`,
        blog: undefined
      }
    })
  }

  private viewDetail(id: number) {
    if (id === this.blogId) return
    this.$router.push({
      query: {
        ...this.$route.query,
        blog: `${id}`,
        state: `${1}`
      }
    })
  }
}
