import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class FAQ {
  @JsonProperty("faq_id") id!: number

  @JsonProperty() question!: string

  @JsonProperty() answer!: string

  @JsonProperty("product_category") productCategory!: string

  @JsonProperty("product_category_id") productCategoryId!: string

  @JsonProperty("product_group") productGroup!: string

  @JsonProperty() sort!: number

  @JsonProperty() status!: string

  @JsonProperty() lang!: string
}
