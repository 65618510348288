

















































































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import InputMixin from "@/types/InputMixin"
import moment from "moment"
import Year from "@/types/News/Year"
import Blog from "@/types/SupportAndDownload/Support/Blog"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import ViewWrapper from "@/views/ViewWrapper.vue"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "BlogList",
  components: { ViewWrapper }
})
export default class BlogList extends mixins(BreakpointMixin, InputMixin) {
  private loadMore = 1

  private maxLoadMore = 1

  private year: number = moment().year()

  private years: Year[] = []

  private blogs: Blog[] = []

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.smAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  @Watch("year")
  private onYearChange() {
    this.listBlogs()
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  mounted() {
    this.fetchData()
  }

  private viewBlog(id: number) {
    this.$emit("view-blog", id)
  }

  /** Generate Link for right click */
  private generateRoute(id: number) {
    return {
      name: "SupportAndDownload",
      query: {
        view: "support",
        id: `${this.$route.query.id || 0}`,
        lang: this.$i18n.locale,
        blog: `${id}`,
        state: `${1}`
      }
    }
  }

  private fetchData() {
    this.listYears()
      .then(() => {
        const queryYear = this.$route.query.year
        if (queryYear) {
          this.year = parseInt(queryYear as string, 10)
        }
      })
      .then(this.listBlogs)
  }

  private listBlogs() {
    SupportApi.listBlogs(this.year).then(({ data }) => {
      this.blogs = _.map(data, (b) => deserialize<Blog>(b, Blog))
      this.maxLoadMore = Math.ceil(this.blogs.length / 6)
    })
  }

  private listYears() {
    return SupportApi.listBlogYears().then(({ data }) => {
      this.years = _.map(data, (d) => deserialize<Year>(d, Year))
      this.year = this.years[0]?.year || this.year
    })
  }
}
