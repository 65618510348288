




























import { Component } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import InputMixin from "../../../types/InputMixin"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "SiteRefList"
})
export default class SiteRefList extends mixins(BreakpointMixin, InputMixin) {
  private productGroups: SiteRefProductGroup[] = []

  mounted() {
    this.fetchData()
  }

  private fetchData() {
    SupportApi.listSiteRefProductGroups().then(({ data }) => {
      this.productGroups = _.map(data, (d) => deserialize<SiteRefProductGroup>(d, SiteRefProductGroup))
    })
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }
}
