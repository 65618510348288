












































































// import { Component, PropSync, Watch } from "vue-property-decorator"
import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import BackBar from "@/components/BackBar.vue"
import SupportApi from "@/api/SupportAndDownload/SupportApi"
import SiteRefProductGroupCategory from "@/types/SupportAndDownload/Support/SiteRefProductGroupCategory"
import SiteRefProductGroup from "@/types/SupportAndDownload/Support/SiteRefProductGroup"
import InputMixin from "../../../types/InputMixin"
import BreakpointMixin from "../../../types/BreakpointMixin"

@Component({
  name: "SiteRefCategory",
  components: { BackBar }
})
export default class SiteRefCategory extends mixins(BreakpointMixin, InputMixin) {
  // @PropSync('productGroupId') syncedProductGroupId!: number;
  // @PropSync('productGroupId')
  groupId!: number

  private productGroups: SiteRefProductGroup[] = []

  private productCategories: SiteRefProductGroupCategory[] = []

  private loading = false

  mounted() {
    // this.groupId = this.productGroupId;
    this.fetchProductGroup()
    this.fetchProductCategory()
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.fetchProductCategory()
  }

  private async fetchProductGroup() {
    let productGroups: SiteRefProductGroup[] = []
    await SupportApi.listSiteRefProductGroups().then(({ data }) => {
      productGroups = _.map(data, (d) => deserialize<SiteRefProductGroup>(d, SiteRefProductGroup))
    })
    return SupportApi.listSiteRefProductGroupsFilter().then(({ data }) => {
      this.productGroups = _.map(data, (d) => deserialize<SiteRefProductGroup>(d, SiteRefProductGroup))
      const _productGroup = _.map(productGroups, (d) => d.productGroupId)
      this.productGroups = this.productGroups.filter(
        (group: SiteRefProductGroup) => _productGroup.includes(group.productGroupId) || group.productGroupId === -1
      )
    })
  }

  private fetchProductCategory() {
    this.loading = true
    const queryGroup = this.$route.query.group
    if (queryGroup) {
      this.groupId = parseInt(queryGroup as string, 10)
    }
    SupportApi.listSiteRefProductCategory(this.groupId)
      .then(({ data }) => {
        this.productCategories = _.map(data, (d) =>
          deserialize<SiteRefProductGroupCategory>(d, SiteRefProductGroupCategory)
        )
        this.productGroups = this.productGroups.filter(
          (productGroup: SiteRefProductGroup) =>
            productGroup.title !== "CALCULATION" &&
            productGroup.title !== "คำนวณวัสดุ" &&
            productGroup.productGroupId !== 23 &&
            productGroup.productGroupId !== 24
        )
      })
      .finally(() => {
        this.loading = false
      })
  }

  private onProductGroupIdChange(groupId: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        group: `${groupId}`
      }
    })
  }

  get selectionWidth() {
    const bp = this.$vuetify.breakpoint
    let width = 0
    if (bp.mdAndDown) {
      width = 307
    } else {
      width = 486
    }
    return `${width}px`
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 392
    }
    if (bp.mdAndUp) {
      return 275
    }
    return 275
  }
}
