import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class SiteRefProductGroupCategory {
  @JsonProperty("product_category_id") productCategoryId!: number

  @JsonProperty() title!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
